import { ICompanyProfileDTO } from "../../services/api";
import { Button, Image, Modal } from "@sizdevteam1/funjoiner-uikit";
import styles from "./MainMenu.module.scss";
import { Link } from "react-router-dom";
import cn from "classnames";
import { ROUTES } from "../../stores/RouterStore";
import SizedBox from "../../components/SizedBox";
import React from "react";
import useStores from "../../hooks/useStores";
import FunJoinerLogo from "src/assets/imgs/fj_logo_sm.svg";
import QrCodesModal from "../ProfilePage/ProfilePage/components/QRCodesModal";

const MainMenu = ({
  company,
  onClose,
}: {
  company: ICompanyProfileDTO;
  onClose: () => void;
}) => {
  const { funboxStore, routerStore, customerStore, authStore } = useStores();

  const [isShowingQrModal, setIsShowingQrModal] = React.useState(false);

  return isShowingQrModal ? (
    <QrCodesModal
      onClose={() => {
        setIsShowingQrModal(false);
        onClose();
      }}
    />
  ) : (
    <Modal
      className={cn(styles.mainMenu)}
      contentClassName={cn("p-0", "rounded-b-[20px]")}
      containerClassName="!rounded-b-[20px] min-w-[366px] max-w-[414px] w-full "
      alignment={"top"}
      onClose={onClose}
      closeOnBackDrop
      disableCloseAnimation
    >
      <Button onClick={onClose} kind="icon" className="absolute right-6 top-6">
        <i className="icon cross-icon !bg-icon-grey-color" />
      </Button>
      {authStore.loggedIn &&
        customerStore.studentsWithCustomerAsParticipant.length > 0 && (
          <Button
            onClick={() => {
              setIsShowingQrModal(true);
            }}
            kind="icon"
            className="absolute left-6 top-6"
          >
            <i className="icon qr-icon !bg-main-color hover:!bg-dark-main-color" />
          </Button>
        )}

      <div className={"typography-h3 bg-background-color"}>
        <div className={cn(styles.topMenuLogo)}>
          <Image
            className="cursor-pointer"
            onClick={() => {
              onClose();
              routerStore.navigate(ROUTES.ROOT);
            }}
            src={company.logo ? company.logo : FunJoinerLogo}
            alt={company.name}
          />
        </div>

        <div
          className={
            "typography-h1 mt-[10px] flex flex-col items-center text-header-color"
          }
        >
          <div className="box-border w-[366px] truncate px-2 pb-2">
            {company.name}
          </div>

          <Button
            kind={"text"}
            className={"!typography-h3 mb-6"}
            onClick={() => {
              onClose();
              routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
                from: ROUTES.ROOT,
              });
            }}
          >
            {funboxStore.selectedFunbox && (
              <i className={cn("icon funbox-icon icon-lg")} />
            )}
            <span className="max-w-[300px] truncate">
              {funboxStore.selectedFunbox?.name ?? "Select FUNBOX"}
            </span>
          </Button>
        </div>
      </div>

      <div className={styles.bottom}>
        <Link
          className={cn("text-h3", styles.link)}
          to={ROUTES.DASHBOARD}
          onClick={onClose}
        >
          Dashboard
        </Link>
        <div className={styles.separator} />
        <Link
          className={cn("text-h3", styles.link)}
          to={ROUTES.PROFILE}
          onClick={onClose}
        >
          Your Profile
        </Link>
        <div className={styles.separator} />
        <Link
          className={cn("text-h3", styles.link)}
          to={ROUTES.HELP}
          onClick={onClose}
        >
          Support
        </Link>
        <div className={styles.separator} />
        <Link
          className={cn("text-h3", styles.link)}
          to={ROUTES.DIRECTIONS_DETAILS}
          onClick={onClose}
        >
          Directions & Details
        </Link>
        <div className={styles.separator} />
        <Link
          className={cn("text-h3", styles.link)}
          to={ROUTES.AVAILABILITY}
          onClick={onClose}
        >
          View Availability
        </Link>

        {funboxStore.selectedFunbox?.type !== "classes" && (
          <>
            <div className={styles.separator} />
            <Link
              className={cn("text-h3", styles.link)}
              to={ROUTES.EXPLORE_OFFERINGS}
              onClick={onClose}
            >
              Explore Offerings
            </Link>
          </>
        )}

        <SizedBox height={24} />
        <Link
          style={{ width: "100%" }}
          className={cn("text-h3", styles.link)}
          to={ROUTES.SCHEDULE}
          onClick={() => {
            onClose();
          }}
        >
          <Button size={"big"} kind={"outlined"}>
            Schedule Dates
          </Button>
        </Link>
        {funboxStore.isBuyCreditsAndSaveEnabled &&
          funboxStore.hasPaidCreditTypes && (
            <>
              <SizedBox height={16} />
              <Link
                style={{ width: "100%" }}
                to={ROUTES.FLEXIBLE_PAYMENTS}
                className={styles.link}
                onClick={onClose}
              >
                <Button size={"big"} className={styles.bigIcon}>
                  <i className={"icon credit-types-icon icon-lg"} />
                  Credits & Packages
                </Button>
              </Link>
            </>
          )}
      </div>
    </Modal>
  );
};
export default MainMenu;
