import { observer } from "mobx-react-lite";
import cn from "classnames";
import { Button, Modal } from "@sizdevteam1/funjoiner-uikit";
import React from "react";
import useStores from "../hooks/useStores";

interface CommunicationPreferencesModalProps {
  className?: string;
}

const CommunicationPreferencesModal = observer(
  (props: CommunicationPreferencesModalProps) => {
    const { customerStore } = useStores();
    if (!customerStore.customerNeedsToSetCommunicationPreferences) return null;
    return (
      <Modal
        headerText={"Communication Preferences"}
        containerClassName={"!max-w-[390px] min-w-[375px]"}
        contentClassName="!p-6"
        className={cn(props.className, "")}
      >
        <div className="flex flex-col">
          <div className="flex flex-col items-center gap-[8px]">
            <i className="icon sun-attention-icon" />
            <div
              className={`typography-small__t flex flex-col gap-2 rounded-[10px] border-[2px] border-solid border-main-color px-4 py-3 text-gray-text-color shadow-big`}
            >
              <div className="">
                You agree to receive Text (SMS) messages about changes and
                updates related to your reservations. SMS/Data rates may apply.
              </div>
              <div>
                See&nbsp;
                <a
                  href="https://funjoin.com/for-business/terms-of-service/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                &nbsp; & &nbsp;
                <a
                  href="https://funjoin.com/for-business/privacy-policy/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .<br></br>
                Reply STOP to unsubscribe at any time.
              </div>
            </div>
          </div>
          <div className="typography-label mt-2 self-center text-surface-purple-color">
            We recommend you <strong>allow</strong> Text (SMS)
          </div>
          <div className="typography-main_sb mt-6 flex gap-[6px] self-center text-text-color">
            <i className="icon communicate-icon bg-main-color" />
            Text (SMS) Transactional
          </div>
          <div className={"mt-4 flex gap-4"}>
            <Button
              kind={"outlined"}
              color={"red"}
              size={"big"}
              onClick={() =>
                customerStore.updateCommunicationPreferences(false)
              }
              autoLoading
              className={"w-full"}
            >
              Deny
            </Button>
            <Button
              size={"big"}
              onClick={() => customerStore.updateCommunicationPreferences(true)}
              autoLoading
              className={"w-full"}
            >
              Allow
            </Button>
          </div>
        </div>
      </Modal>
    );
  },
);
export default CommunicationPreferencesModal;
