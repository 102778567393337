import React from "react";

export interface ISVGProps {
  color?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}

const Border: React.FC<ISVGProps> = ({ color = "#ffffff", svgProps }) => {
  return (
    <>
      <div className="h-[10px] bg-on-main-color"></div>
      <div className="mt-[-6px]">
        <svg
          width="100%"
          height="10"
          viewBox="0 0 366 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...svgProps}
        >
          <path
            d="M367 0H0L11.5229 9.82228L22.9886 0.0486979L34.4544 9.82228L45.9186 0.0499912L57.3828 9.82228L68.8486 0.0486987L80.3143 9.82228L91.7801 0.0487019L103.246 9.82228L114.712 0.0487019L126.177 9.82228L137.643 0.0486988L149.109 9.82228L160.575 0.0486857L172.04 9.82228L183.502 0.0521456L194.964 9.82228L206.43 0.0486857L217.895 9.82228L229.361 0.0486988L240.827 9.82228L252.292 0.0486988L263.758 9.82228L275.224 0.0486988L286.69 9.82228L298.155 0.0486988L309.621 9.82228L321.085 0.0504157L332.549 9.82228L344.013 0.0499864L355.477 9.82228L367 0Z"
            fill="white"
            stroke={color}
          />
        </svg>
      </div>
    </>
  );
};
export default Border;
