import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import PaymentStore from "./PaymentStore";
import api, {
  ICardDTO,
  ICustomerStripePaymentMethodDTO,
} from "../services/api";
import { StripeElements } from "@stripe/stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import notificator from "../services/systemNotifications/notificationCenterService";
import FunboxStore from "./FunboxStore";
import { ICustomerInvoicePaymentOptionDTO } from "src/services/api/payment-methods";

export default class PaymentMethodStore {
  constructor(
    private paymentStore: PaymentStore,
    private funboxStore: FunboxStore,
  ) {
    makeObservable(this);
  }

  @observable
  loading = false;

  @observable
  stripePaymentMethods: ICustomerStripePaymentMethodDTO = {
    default_payment_method: undefined,
    payment_methods: [],
  };

  @computed
  get invoicePaymentMethod(): ICustomerInvoicePaymentOptionDTO | null {
    const selectedFunboxInvoiceSettings =
      this.funboxStore.selectedFunbox?.invoice_payment_settings;
    if (selectedFunboxInvoiceSettings == null) return null;
    return selectedFunboxInvoiceSettings.is_enabled
      ? {
          description: selectedFunboxInvoiceSettings.description,
          is_description_provided:
            selectedFunboxInvoiceSettings.description.trim.length > 0,
        }
      : null;
  }

  @action.bound
  async remove(paymentMethodId: string) {
    await api.paymentMethods.remove(paymentMethodId);
    await this.sync();
  }

  private setupIntentClientSecret?: string;

  @action
  createPaymentMethodFromElements = async (
    elements: StripeElements,
  ): Promise<ICardDTO> => {
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) throw new Error("No Card number element");
    try {
      return await this.paymentStore.createPaymentMethod(cardElement);
    } catch (e) {
      notificator.error(
        "Error",
        typeof e === "object" && e != null && "message" in e ? e.message : e,
      );
      throw e;
    }
  };

  @action.bound
  async attachNewCard(paymentMethod: ICardDTO) {
    try {
      const clientSecret =
        this.setupIntentClientSecret == null
          ? await api.paymentMethods.getSetupIntent()
          : this.setupIntentClientSecret;

      await this.paymentStore.confirmSetupIntent(
        clientSecret,
        paymentMethod.payment_method_id,
      );

      this.setupIntentClientSecret = undefined;

      runInAction(() => {
        this.stripePaymentMethods.payment_methods.push(paymentMethod);
        if (
          this.stripePaymentMethods.default_payment_method == null &&
          this.stripePaymentMethods.payment_methods.length === 1
        ) {
          this.stripePaymentMethods.default_payment_method =
            paymentMethod.payment_method_id;
        }
      });

      return paymentMethod;
    } catch (e) {
      notificator.error(
        "Error",
        typeof e === "object" && e != null && "message" in e ? e.message : e,
      );
      throw e;
    }
  }

  @action.bound
  async setDefaultPaymentMethod(paymentMethodId: string) {
    await api.paymentMethods.setDefault(paymentMethodId);
    this.stripePaymentMethods.default_payment_method = paymentMethodId;
  }

  @action.bound
  async sync() {
    this.loading = true;
    try {
      const methods = await api.paymentMethods.get();
      runInAction(() => {
        this.stripePaymentMethods = methods.stripe_payment_methods;
      });
    } catch (e) {
      notificator.error("Payment methods loading failed!", e);
    } finally {
      this.loading = false;
    }
  }
}
