import React from "react";
import cn from "classnames";
import Notification from "rc-notification";
import styles from "./styles.module.scss";
import { NotificationInstance } from "rc-notification/es/Notification";

let notification: NotificationInstance;
Notification.newInstance({}, (n) => {
  notification = n;
});

interface IProps {
  title: string;
  onClose: () => void;
  error?: boolean;
  children?: React.ReactNode;
}

const NotificationItem: React.FC<IProps> = (props) => {
  const icon = props.error ? "disconnect" : "check-in-object-2";
  return (
    <div
      className={cn(styles.notificationItem, { [styles.error]: props.error })}
      onClick={props.onClose}
    >
      <div className={styles.icon}>
        <i className={`icon ${icon}-icon`} />
      </div>
      <div>
        <div className={cn("text-h3", styles.title)}>{props.title}</div>
        <div className={cn("text-small", styles.description)}>
          {props.children}
        </div>
      </div>
      <div className={styles.closeIcon}>
        <i className={"icon close-icon icon-hover"} />
      </div>
    </div>
  );
};

class NotificationCenterService {
  constructor(public duration: number = 15) {}

  error = (title: string, error?: unknown) => {
    const key = Date.now();
    const description = this.buildErrorDescription(error);
    const close = () => notification.removeNotice(key);
    notification.notice({
      key,
      content: (
        <NotificationItem error title={title} onClose={close}>
          {description}
        </NotificationItem>
      ),
      duration: this.duration,
    });
  };
  success = (title: string, description?: React.ReactNode) => {
    const key = Date.now();
    const close = () => notification.removeNotice(key);
    notification.notice({
      key,
      content: (
        <NotificationItem title={title} onClose={close}>
          {description}
        </NotificationItem>
      ),
      duration: this.duration,
    });
  };
  private buildErrorDescription = (error?: unknown) => {
    if (!error) return "";
    if (typeof error === "string") return error;
    if (typeof error === "object") {
      if ("message" in error && typeof error.message === "string") {
        return error.message;
      }
      return React.isValidElement(error) ? error : JSON.stringify(error);
    }
    return String(error);
  };
}

const notificator = new NotificationCenterService(10);
export default notificator;
