import {
  SessionInProgram,
  SessionsDisplayOption,
} from "../services/api/availability";
import React from "react";
import useStores from "../hooks/useStores";
import cn from "classnames";
import { ISOString } from "@sizdevteam1/funjoiner-uikit";
import formatStartEndDate from "src/util/formatStartEndDate";
import { GroupedSessions } from "./GroupedSessions/GroupedSessions";
import SessionRow from "./SessionRow/SessionRow";

interface ProgramInfoCardProps {
  className?: string;
  scheduleSetName: string;
  programInfo: {
    start_date: ISOString;
    end_date: ISOString;
    location_id: number;
    program_type_name: string;
    sessions_display_option?: SessionsDisplayOption;
    sessions?: SessionInProgram[];
  };
}

export const ProgramInfoCard: React.FC<ProgramInfoCardProps> = ({
  programInfo,
  scheduleSetName,
  className,
}) => {
  const { commonStore } = useStores();
  return (
    <div className={cn("overflow-auto rounded-[10px] shadow-big", className)}>
      <div className={"space-y-2 bg-on-main-color p-4"}>
        <div className={"typography-h3 text-header-color"}>
          {scheduleSetName}
        </div>
        <div
          className={"typography-main flex  items-center text-gray-text-color"}
        >
          <i className={"icon locations-icon mr-1"} />
          {commonStore.locationsById[programInfo.location_id]?.name}
          <span className={"typography-label ml-auto"}>
            {programInfo.program_type_name}
          </span>
        </div>
      </div>
      <div>
        {"sessions" in programInfo &&
          "sessions_display_option" in programInfo &&
          programInfo.sessions != null &&
          programInfo.sessions_display_option != null && (
            <SessionsView
              sessions={programInfo.sessions}
              sessionsDisplayOption={programInfo.sessions_display_option}
            />
          )}
        {programInfo.sessions == null && (
          <div className="typography-main p-4 text-center text-text-color">
            {formatStartEndDate({
              start_date: programInfo.start_date,
              end_date: programInfo.end_date,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const SessionsView = ({
  sessions,
  sessionsDisplayOption,
}: {
  sessions: SessionInProgram[];
  sessionsDisplayOption: SessionsDisplayOption;
}) => {
  if (sessionsDisplayOption === "DEFAULT") {
    return (
      <div>
        {sessions.map((session) => (
          <SessionRow className="pr-4" key={session.id} session={session} />
        ))}
      </div>
    );
  }
  return (
    <GroupedSessions
      displayOption={sessionsDisplayOption}
      sessions={sessions}
    />
  );
};
