import { Button, Image } from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { matchPath } from "react-router-dom";
import useStores from "src/hooks/useStores";
import { ROUTES } from "src/stores/RouterStore";
import styles from "./AppHeader.module.scss";
import MainMenu from "./MainMenu";
import FunboxMenu from "./FunboxMenu";
import FunJoinerLogo from "src/assets/imgs/fj_logo_sm.svg";
import classNames from "classnames";
import QrCodesModal from "../ProfilePage/ProfilePage/components/QRCodesModal";

const AppHeader: React.FC = observer(() => {
  const { commonStore, funboxStore, routerStore } = useStores();
  const company = commonStore.companyProfile;

  const isShowingHeaderItems = !matchPath(
    {
      path: ROUTES.SELECT_FUNBOX_PAGE,
      caseSensitive: false,
    },
    routerStore.currentPath
  );

  const isFunboxMenuVisible =
    [
      ROUTES.FLEXIBLE_PAYMENTS,
      ROUTES.AVAILABILITY,
      ROUTES.SCHEDULE,
      ROUTES.EXPLORE_OFFERINGS,
    ].some(
      (path) =>
        !!matchPath(
          {
            path: path,
            caseSensitive: true,
            end: true,
          },
          routerStore.currentPath
        )
    ) && funboxStore.selectedFunbox != null;

  const isWelcomeScreenHeader = [
    ROUTES.WELCOME_SCREEN,
    ROUTES.LOCATIONS,
    ROUTES.SELECT_FUNBOX_PAGE,
    ROUTES.SELECT_FUNBOX_PAGE_BY_ID_ACTION,
    ROUTES.SELECT_FUNBOX_PAGE_BY_URL_PART_ACTION,
  ].some((path) => !!matchPath({ path: path }, routerStore.currentPath));

  const [isShowingFunboxMenu, setIsShowingFunboxMenu] = React.useState(false);
  const [isShowingMainMenu, setIsShowingMainMenu] = React.useState(false);
  const [isShowingQrModal, setIsShowingQrModal] = React.useState(false);

  const openMainMenu = () => {
    setIsShowingMainMenu(true);
    setIsShowingFunboxMenu(false);
  };

  return isWelcomeScreenHeader ? (
    <WelcomeScreenPageHeader />
  ) : (
    <div className={classNames("relative z-10 mb-5")}>
      <div className="w-full bg-[#f6f7f8]">
        <div className={styles.header}>
          <Image
            className={styles.logo}
            src={company?.logo || FunJoinerLogo}
            alt={company?.name}
            onClick={() => routerStore.navigate(ROUTES.ROOT)}
          />
          {isShowingHeaderItems && (
            <>
              {isFunboxMenuVisible && (
                <Button
                  kind={"text"}
                  className={"!typography-h3  ml-3 max-h-[80px] max-w-[210px]"}
                  onClick={() => setIsShowingFunboxMenu(!isShowingFunboxMenu)}
                >
                  <div className="flex items-center">
                    <i
                      className={cn(
                        "icon icon-lg ml-1",
                        isShowingFunboxMenu
                          ? "chevron-down-icon"
                          : "funbox-icon"
                      )}
                    />
                    <div className="max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {funboxStore.selectedFunbox.name}
                    </div>
                  </div>
                </Button>
              )}
              <Button
                kind={"text"}
                className={cn("!typography-main ml-auto")}
                onClick={openMainMenu}
              >
                Menu
                <i className={cn("icon burger-icon", "icon-lg ml-2 !mr-0 ")} />
              </Button>
            </>
          )}
        </div>
      </div>
      {isShowingFunboxMenu && (
        <FunboxMenu onClose={() => setIsShowingFunboxMenu(false)} />
      )}
      {isShowingMainMenu && (
        <MainMenu
          company={commonStore.companyProfile}
          onClose={() => setIsShowingMainMenu(false)}
        />
      )}
      {isShowingQrModal && (
        <QrCodesModal onClose={() => setIsShowingQrModal(false)} />
      )}
    </div>
  );
});

const WelcomeScreenPageHeader = () => {
  const {
    commonStore: { companyProfile },
    routerStore,
    authStore,
  } = useStores();

  const isMenuVisibleAndLogoClickable = !!matchPath(
    {
      path: ROUTES.WELCOME_SCREEN,
      caseSensitive: true,
      end: true,
    },
    routerStore.currentPath
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="relative mx-auto mt-[14px] mb-4 flex w-[366px] items-center justify-center ">
      <Image
        className={classNames(
          "!h-[80px] !w-[80px]",
          isMenuVisibleAndLogoClickable && "cursor-pointer"
        )}
        imageClassName="h-[80px] w-[80px]"
        src={companyProfile?.logo || FunJoinerLogo}
        alt={companyProfile?.name}
        onClick={() =>
          isMenuVisibleAndLogoClickable &&
          routerStore.navigate(
            authStore.loggedIn ? ROUTES.DASHBOARD : ROUTES.SIGN_IN
          )
        }
      />
      {authStore.loggedIn && isMenuVisibleAndLogoClickable ? (
        <Button
          kind={"text"}
          className={classNames("!typography-main absolute top-[14px] right-0")}
          onClick={() => setIsMenuOpen(true)}
        >
          Menu
          <i
            className={classNames("icon burger-icon", "icon-lg ml-2 !mr-0 ")}
          />
        </Button>
      ) : (
        isMenuVisibleAndLogoClickable && (
          <Button
            kind="text"
            className="absolute top-[14px] right-0"
            onClick={() => routerStore.navigate(ROUTES.SIGN_IN)}
          >
            Log in
          </Button>
        )
      )}
      {isMenuOpen && (
        <MainMenu
          company={companyProfile}
          onClose={() => setIsMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default AppHeader;
