import { useLayoutEffect } from "react";
import { To, useLocation } from "react-router-dom";
import { useHistory } from "../BrowserRouter";

export const ImmediateRedirect = ({
  to,
  replace,
  state,
}: {
  to: To;
  replace?: boolean;
  state?: unknown;
}) => {
  const history = useHistory();
  useLayoutEffect(() => {
    if (replace) {
      history.replace(to, state);
    } else {
      history.push(to, state);
    }
  }, [to, replace, state, history]);

  return <div />;
};
